const About = 'About'
// const Projects = 'Projects'
const Terminal = 'Terminal'
const Contact = 'Contact'
const Resume = 'Resume'

export const windowNames = { About, Terminal, Contact, Resume }

export const iconStates = {
  active: 'active',
  inactive: 'inactive',
}
